import { Injectable } from "@angular/core";
import { MainConversionInterface } from "../../../../core/interfaces/conversion/main-conversion.interface";
import { OrganizationUnitDetailsDTO, OrganizationUnitDTO, OrganizationUnitFormDTO } from "../dto/organization-unit.dto";
import { CurrentUserPreferenceStore } from "../../../../core/store/current-user-preference.store";
import { TableConversion } from "../../../../shared/conversion/table.conversion";


@Injectable({
  providedIn: 'root',
})
export class OrganiztionUnitConversion
  implements MainConversionInterface<OrganizationUnitDTO, OrganizationUnitFormDTO, OrganizationUnitDetailsDTO>
{
  private lang: string;

  constructor(
    private currentUserPreferenceStore: CurrentUserPreferenceStore,
    private tableConv: TableConversion
  ) {
    this.lang = this.currentUserPreferenceStore.languageKey;
  }

  resToList(response: any[]): any {
    throw new Error('Method not implemented.');
    
  }
  formGroupToForm(formGroup: any): any {
    throw new Error('Method not implemented.');
  }
  resToForm(response: any) {
    throw new Error('Method not implemented.');
  }
  resToDetails(response: any): OrganizationUnitDetailsDTO {
    
    return {
      id: response.id,
      title: response[`title_${this.lang}`],
      status: response.status,
      color_code: response.color_code,
      
    };
  }

 
}
